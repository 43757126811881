import React from 'react'
import _get from 'lodash/get'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade'
import CTA from '../components/common/CTA'
import Layout from '../components/common/Layout'
import Topic from '../components/help/Topic'
import { categories } from '../data/categories'

export const TopicPage = ({ data }) => {
  const title = data.page.frontmatter.title
  const subtitle = data.page.frontmatter.subtitle
  const docs = data.docs.edges
    .filter(({ node }) => node.frontmatter.template === 'SingleArticle')
    .flatMap(({ node }) => node.frontmatter)
    .filter(d => d.topic === data.page.frontmatter.title)

  const guides = data.guides.edges
    .filter(({ node }) => node.frontmatter.template === 'SingleArticle')
    .flatMap(({ node }) => node.frontmatter)
    .filter(d => d.topic === data.page.frontmatter.title)

  const isGuides = Boolean(
    data.guides.edges.find(
      ({ node }) => node.frontmatter.title === data.page.frontmatter.title
    )
  )

  return (
    <Layout>
      <main>
        <Topic
          title={title}
          subtitle={subtitle}
          docs={isGuides ? guides : docs}
          categories={isGuides ? categories['guides'] : categories['docs']}
          tag={isGuides ? 'guides' : 'docs'}
        />
        <Fade up duration={1000} delay={0} distance="30px">
          <CTA text="Ready to level up your map-making process?" />
        </Fade>
      </main>
    </Layout>
  )
}

export default TopicPage

export const pageQuery = graphql`
  ## Query for TopicPage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query TopicPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      id
      frontmatter {
        title
        subtitle
        template
      }
    }

    docs: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "docs" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            template
            featuredImage
            featured
            topic
            readTime
          }
        }
      }
    }
    guides: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "guides" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            template
            featuredImage
            featured
            topic
            readTime
          }
        }
      }
    }
  }
`
