import React, { useState, useEffect } from 'react'
import styles from './Topic.module.scss'
import GridItem from './GridItem'
import { Link } from 'gatsby'
import Categories from './Categories'

const Topic = ({ title, subtitle, docs, categories, tag }) => {
  const getTitle = tag => {
    if (tag === 'docs') {
      return 'Documentation'
    } else if (tag === 'guides') {
      return 'Guides'
    }
  }

  return (
    <>
      <div className={styles.section}>
        <div className={styles.content}>
          <div className={styles.layout}>
            <div className={styles.leftContainer}>
              <Categories docs={docs} categories={categories} tag={tag} />
            </div>
            <div className={styles.rightContainer}>
              <div className={styles.headerContent}>
                <div className={styles.heading}>
                  <div className={styles.breadcrumb}>
                    <Link to="/help/"> Help</Link> /{' '}
                    <Link to={`/help/${tag}/`}>{getTitle(tag)}</Link> /{' '}
                    <Link
                      to={`/${tag}/${title
                        .toLowerCase()
                        .replace(/\s+/g, '-')}/`}
                    >
                      {title}
                    </Link>
                  </div>
                  <h1>{title}</h1>
                  <p>{subtitle}</p>
                </div>
              </div>
              <div className={styles.cardsSection}>
                <div className={styles.grid}>
                  {docs.map(doc => (
                    <GridItem
                      title={doc.title}
                      image={doc.featuredImage}
                      readtime={doc.readTime}
                      tag={tag}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Topic
